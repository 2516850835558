@font-face {
  font-family: 'Arial';
  src: url('./fonts/arial.ttf');
}

    .area_logos{
      width: 99%;
      display: flex;
      justify-content: center;
      padding: 10px 0;
    }

    .logoAuditoria img{
        width: 180px;
    }
    
    .logoRisc img{
        width: 50px;
    }

    .txt_info{
      font-size: 12px;
      font-weight: bolder;
      margin: 10px;
    }

    .auditoria_title{
      margin: 10px 0;
    }

    .area_info{
      display: flex;
      flex-direction: column;
      width: 99%;
      border: solid 2.5px rgb(124, 124, 124);
      margin: 0 auto  ;
    }

    .area_info p{
        font-size: 12px;
    }

    .row{
      display: flex;
      justify-content: center;
      padding: 15px 5px;
      border-bottom: solid 1px rgb(160, 160, 160);
    }

    .area_info :last-child{
      border-bottom: none;
    }

    .grow2R{
      display: flex;
      align-items: center;
      flex-grow: 2;
      padding: 5px;
      max-width: 50%;
    }

    .grow1R{
      display: flex;
      align-items: flex-start;
      flex-grow: 1;
      padding: 5px;
      max-width: 50%;
    }

    .font1{
      font-weight: bold;
      margin-right: 0.3rem;
    }

    .font2{
      font-weight: lighter;
      color: rgb(100, 100, 100);
      margin-right: 0.3rem;
    }
    
    .font3{
      font-weight: normal;
    }

    .resumo{
      /* margin: 20px 0; */
      width: 98%;
      display: flex;
      flex-direction: column;
    }

    .resumo p{
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      text-align: justify;
    }

    .row-quest{
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    .imgEvidencia{
      margin: auto;
      width: 90%;
      height: auto;
      margin-bottom: 1.2rem;
    }

    .row-quest .part1,.part2,.part3{
      display: flex;
      flex-direction: column;  
    }

    .part1,.part2,.part3{
      margin: 2px;
    }

    .row-quest .part1{
      width: 95%;
    }

    .row-quest .part2{
      width: min-content;
    }

    .row-quest .part3{
      width: 100%;
    }

    .font-1{
      margin: 5px 0;
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      text-align: justify;
    }

    .font-2{
      font-weight: bold;
      padding: 5px 0;
      line-height: 1.5rem;
      font-size: 16px;
      font-family: 'Arial', sans-serif;
      text-align: justify;
    }

    .assinaturas{
      width: 98%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 10px 0;
    }

    .assinaturas p{
        font-size: 12px;
    }

    .campGraficos{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      height: auto;
    }

    .graficoMultiReport{
      width: 90%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1rem 0;
    }


    .graficoMultiReport h2{
      font-size: 12px;
      color: #000;
      font-weight: bold;
    }

    .graficoMultiReport text{
      font-size: 12px;
      color: #000;
    }

    .svg_auto {
      padding: 0.5rem;
      width: 100%;
      margin: 0px;
    }

    .svg_auto svg {
      width: auto;
      height: auto;
    }
    
    .graficoMultiReport svg g text tspan{
      font-size: 10px;
    }

    .graficoMultiReport span{
      font-size: 10px;
    }

    .camp_assina{
      width: 50%;
      margin: 25px auto ;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding:0rem 0.5rem;
    }

    .linha{
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid black;
    }

    .outras_assinaturas{
      width: 98%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin: 20px 0;
    }

    .outras_assinaturas p{
        font-size: 12px;
    }

    .camp_assina_demais{
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0;
    }
