.answer_item,
.answer_item_optional {
  background-color: white;
  color: #ab1414;
  border-radius: 15px;
  padding-bottom: 0.5rem;
}
.answer_item .answer_label,
.answer_item_optional .answer_label {
  color: #ab1414;
  display: block;
  width: 100%;
  padding: 0.5rem;
  font-size: 1.2rem;
  font-family: Gibson-Bold;
  align-items: center;
}

.question-form-container {
  width: 100%;
}

.answer_item_optional .answer_label span {
  font-size: 0.8rem;
  font-family: Gibson-Regular;
}

.bg_input {
  background-color: #ab141486;
  width: 90%;
  padding: 10px;
  border-radius: 1rem;
  margin: 10px auto;
}

.answer_item input,
.answer_item_optional input,
.answer_item textarea,
.answer_item_optional textarea,
.painel_textarea {
  width: 100%;
  padding: 1.3rem 1.5rem;
  border-radius: 1rem;
  border: none;
  max-width: 100%;
  min-width: 100%;
}
.answer_item_optional .answer_label {
  display: flex;
  justify-content: space-between;
}
.answer_item_optional .answer_label svg {
  margin-right: 0.5rem;
}
.img_miniature {
  height: 100%;
  width: 20%;
  margin: 0 0.5rem;
}
.answer_item_optional {
}
.question_tittle {
  display: flex;
  justify-content: space-between;
}
.question_answered {
  background-color: none;
  width: min-content;
  padding: 0.3rem;
  border-radius: 50%;
  display: flex;
}
.question_answered svg {
  width: 2rem;
  height: 2rem;
  fill: #c73333;
}

.form_answers_row {
  display: block;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0.7rem 0;
}

.plus_icon {
  margin: 0px 1rem;
  padding: 0;
  border: 1px solid white;
  width: max-content;
  padding: 0.2rem;
}

.plus_icon {
  padding: 0.3rem 0.5rem;
  background: #ab1414;
  border-radius: 0.3rem;
  color: white;
  height: max-content;
  margin: auto 0.8rem;
}

.plus_icon label {
  width: min-content;
  font-size: 1rem;
}
.form_button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #c73333;
  color: white;
  font-family: Gibson-Bold;
  margin: 10px auto;
  font-size: 1.3rem;
}

.form_button_active {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #ab1414 !important;
  color: white;
  font-family: Gibson-Bold;
  margin: 10px auto;
  font-size: 1.3rem;
}

.modal_container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 0.5rem;
  z-index: 3;
  display: flex;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  -webkit-overflow: hidden;
  -moz-overflow: hidden;
}

.preview_modal {
  background-color: white;
  position: fixed;
  padding: 0.5rem;
  border-radius: 1rem;
  width: 96vw;
  height: 96vh;
  top: 2;
  bottom: 2;
  left: 2;
  right: 2;
  overflow-y: scroll;
  -webkit-overflow-y: scroll;
}
.preview_modal .preview_title {
  text-align: center;
  font-size: 2rem;
  margin: 1rem 0;
}

.preview_modal .preview_title h3 {
  color: #ab1414;
}

.preview_footer {
  display: flex;
  justify-content: space-between;
}

.preview_footer p,
.preview_footer label {
  font-size: 1rem;
  font-family: Gibson-Bold;
  margin: 0.5rem 0.2rem;
}
.preview_footer div {
  display: flex;
}
.preview_buttons {
  display: flex;
  justify-content: center;
}

@media (min-width: 767px) {
  .preview_modal {
    background-color: white;
    position: fixed;
    padding: 0.5rem;
    border-radius: 1rem;
    width: 60vw;
    height: 96vh;
    top: 2;
    bottom: 2;
    left: 2;
    right: 2;
    overflow-y: scroll;
    -webkit-overflow-y: scroll;
  }

  .preview_modal .preview_title {
    text-align: center;
    font-size: 2rem;
    margin: 1rem 0;
  }

  .preview_modal .preview_title h3 {
    color: #ab1414;
    font-size: 2rem;
  }
}
