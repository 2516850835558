.container {
  margin: 0px auto;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: 100vw;
  max-width: 100vw;
}
#__next {
  display: flex;
}
.d-none {
  display: none;
}
.content {
  background-color: white;
  border-radius: 8em 8em 0 0 / 2em;
  min-height: 90vh;
  width: 100%;
  margin-top: 10vh;
  z-index: 2;
}
.content Form {
  margin-top: 1vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.page-title {
  background-image: linear-gradient(#ba0000, #c73333c0, #ff9e9ecb 10%);
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0;
  padding-top: 10px;
}
.page-title a {
  position: absolute;
  top: 3vw;
  left: 7vw;
}

.page-title a svg {
  width: 10vw;
  height: auto;
  color: white;
}

.page-title h2 {
  color: white;
  font-size: 2rem;
  padding-bottom: 1rem;
}
.text-1 {
  color: #ba0000d8;
  font-size: 1.5rem;
  font-family: Gibson-Bold;
}
.text-2,
.setor-data .setor-label {
  color: #7d7f8c;
  font-size: 1.2rem;
  font-family: Gibson-Bold;
}
.row {
  display: flex;
  justify-content: space-between;
}
.row .col {
  display: flex;
  /* justify-content: space-between; */
}
/*Auditoria */
.aud-opc-buttons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 12px 5% 20px;
}
.aud-opc-buttons a {
  background-color: white;
  padding: 20px 0;
  width: 29%;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ab1414;
  font-family: Gibson-Regular;
  font-size: 0.9rem;
  margin: 25px 0;
}

.aud-group {
  text-align: center;
  margin: 10px 8%;
}

.aud-group img {
  width: 30vw;
  max-width: 250px !important;
}

.aud-group .selections {
  margin: 15px 0;
}
.aud-group select,
.aud-group .label-auditor,
.setor-data .setor-label {
  padding: 1.2rem 2rem;
  border: none;
  width: 100%;
  margin: 0.2rem 0;
  border-radius: 1rem;
}
.aud-group select {
  background-color: #ba00008a;
  color: white;
  font-family: Gibson-Bold;
  -webkit-appearance: textfield;
  font-size: 1.2rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 0;
}

.select {
  background: #ba000069 url('../../assets/seta.svg') 95.5% 50% no-repeat;
  margin: 25px 0;
  padding: 0;
  z-index: 2;
  border-radius: 1rem;
  z-index: 2;
}

.aud-group .label-auditor {
  background-color: #ba0000cb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 5px 8px;
}
.label-auditor .auditor-name {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.label-auditor .auditor-name label {
  font-size: 0.9rem;
  margin-left: 23px;
}
.label-auditor .auditor-name p {
  font-size: 1.2rem;
  margin: 5px 0 0 23px;
  font-family: Gibson-Bold;
}

.area-user {
  background-color: white;
  border-radius: 20%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.encerrada,
.check {
  margin: 0;
  padding: 0;
  position: relative;
}

.check svg {
  position: absolute;
  width: 17px;
  height: 17px;
  color: #fff;
  top: 10px;
  left: 8px;
}

.area-user svg {
  width: 50px;
  height: 50px;
  border-radius: 20%;
  color: #ab1414;
  padding: 5px;
}

.sector-body,
.questions-body,
.questions-body-form,
.audit-body {
  background-color: white;
  border-radius: 1rem;
  padding: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.body-title {
  color: #c73333;
  font-size: 1.4rem;
  font-family: Gibson-Bold;
}
/* SETOR */
.sector-body {
  display: flex;
  flex-wrap: wrap;
}

.row {
  align-items: center;
}

.row .setor {
  font-size: 1.1rem;
  font-family: Gibson-Bold;
  white-space: nowrap;
}

.row .setor-nome {
  font-size: 0.8rem;
  font-family: Gibson-Regular;
  white-space: nowrap;
  margin: auto 0.2rem;
}

.row .question-atual {
  font-size: 1.5rem;
}

.row .question-total {
  font-size: 1.5rem;
  font-family: Gibson-Regular;
}

.row .font-bold {
  font-size: 1rem;
  font-family: Gibson-Bold;
}

.row .font-regular {
  font-size: 1rem;
  font-family: Gibson-Regular;
}

.setor-row {
  width: 90%;
  background-color: #ba0000c2;
  margin: 30px auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.setor-row label,
.setor-row p {
  /* background-color: #E9EBEF; */
  color: white;
  font-size: 1.2rem;
  font-family: Gibson-Bold;
}
.setor-row .col {
  margin: 0.2rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.4rem 1.5rem;
  /* border:1px solid gray; */
  border-radius: 0.6rem;
}
.setor-data {
}
.setor-data .setor-label {
  background-color: #e9ebef;
  display: flex;
  justify-content: space-between;
}
.sector-body span {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px;
  width: 32%;
  background-color: #353a50;
  border-radius: 1rem;
  text-align: center;
  display: block;
  color: white;
  font-family: Gibson-Bold;
  margin: 0.3%;
}
/* Questionário */
.questions-body {
}
.questions-body .body-title {
  margin: 1rem 0;
}
.question-number {
  font-size: 1.4rem;
  font-family: Gibson-Bold;
  margin: 0.5rem 0;
}
.question-item {
  border-radius: 1rem;
  padding: 0.7rem 1rem;
  margin: 0.7rem auto;
  width: 95%;
}
.question-excerpt {
  margin: 1rem 0;
  text-align: justify;
}
/* .question-excerpt p {
  text-transform: capitalize;
} */
.question-footer {
  display: flex;
  justify-content: space-between;
}
.question-footer p,
.question-footer label {
  font-size: 1.2rem;
  font-family: Gibson-Bold;
  margin: 0.1rem 0;
}

.question-form-container {
  width: 100%;
}

.box-dark {
  background-color: #c73333c4;
}
.box-dark .question-excerpt,
.box-dark p,
.box-dark label,
.box-dark .question-number {
  color: white;
}
.box-light .question-excerpt,
.box-light p,
.box-light label,
.box-light .question-number {
  color: #ab1414;
}
.box-light {
  background-color: #c733331e;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
}
.questions-body-form {
  background-color: #ab1414c5;
  width: 90%;
  padding: 1.1em;
  margin: auto;
  z-index: 3;
}

.question-form-container div {
  background-color: white;
}

.questions-body-form .button-row {
  display: flex;
  justify-content: space-between;
}

.button-row-center {
  display: flex;
  justify-content: center;
  margin: 15px 0 30px;
}

.button-row-center button {
  padding: 1.2rem 3rem;
  border: none;
  border-radius: 0.75rem;
  background-color: #c7333379;
  color: white;
  font-family: Gibson-Bold;
  margin: auto;
  font-size: 1.3rem;
}

.questions-body-form button {
  padding: 1rem 2rem;
  border: none;
  border-radius: 0.75rem;
  background-color: white;
  color: #ab1414;
  font-family: Gibson-Bold;
  margin: auto;
}
.answer-item-optional .answer-item {
  display: flex;
  flex-direction: column;
}
.answer-item p,
.answer-item label {
  margin: 1rem;
}
.button-row {
  margin-top: 0.5rem;
}
.aud-opc-group {
  width: 100%;
  background-color: #a0080852;
  padding-top: 3vh;
  text-align: center;
  color: white;
  font-size: 0.9rem;
  font-family: Gibson-Bold;
  border-radius: 8em 8em 0 0 / 2em;
  /* margin-top: 3vw; */
}
footer.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  padding: 25px 8%;
  z-index: 2;
  background-color: #ab1414;
  border-radius: 4em 4em 0 0 / 2em;
}
footer.footer a {
  padding: 5px;
  width: 24%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
footer.footer a.active,
footer a.opcao:hover {
  background-color: white;
  border-radius: 4em 4em 0 0 / 2em;
  color: #ab1414;
}

footer a.opcao span {
  display: none;
}
svg {
  height: 35px;
  width: 35px;
}

footer a.opcao svg {
  color: white;
}

footer a.opcao:hover span,
footer a.opcao:hover svg {
  color: #ab1414;
  display: flex;
}

.progressAll {
  width: 100%;
  border: solid 1px white;
  display: flex;
}
.progressAtual {
  background-color: white;
  border: solid 5px white;
  display: flex;
  border-left: none;
  border-right: none;
}

.questions-body span {
  width: 100%;
}

@media (min-width: 767px) {
  * {
    font-size: 1.1rem;
  }

  html {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .container {
    display: block;
    margin: 0px auto;
    padding: 0.05rem;
    display: flex;
    flex-direction: column;
    min-width: 100vw;
    min-height: 100vh;
  }
  #__next {
    display: flex;
  }
  .d-none {
    display: none;
  }
  .content {
    background-color: white;
    border-radius: 8em 8em 0 0 / 2em;
    height: 100%;
    width: 100%;
    max-width: 100%;
    margin-top: -5vw;
  }
  .content Form {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: space-between;
  }
  .page-title {
    background-image: linear-gradient(#ba0000, #ab1414e7, #c73333c7, #ff9e9e59);
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1% 0 7%;
    position: relative;
  }

  .page-title h2 {
    color: white;
    font-size: 3.5rem;
    padding: 1rem;
  }

  .page-title a {
    position: absolute;
    top: 20%;
    left: 5%;
  }

  .page-title a svg {
    width: 60px;
    height: auto;
    color: white;
  }

  .area-user {
    background-color: white;
    border-radius: 20%;
    height: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .area-user img {
    width: 75px;
    height: 70px;
    border-radius: 20%;
  }

  .aud-group {
    text-align: center;
    margin: 25px 25vw;
  }

  .aud-opc-buttons {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    padding: 0 22% 30px;
  }

  .mg-ledo {
    padding: 0 22%;
  }

  .align-center {
    margin: 20px 0 !important;
  }

  footer.footer {
    width: 100vw;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    position: relative;
    bottom: 0;
    z-index: 2;
    background-color: #ab1414;
    border-radius: 4em 4em 0 0 / 2em;
    padding: 25px 24%;
  }
}
.w-100 {
  min-width: 100%;
}
