@font-face {
  font-family: Gibson-Regular;
  src: url('./fonts/Gibson-Regular.ttf');
}

@font-face {
  font-family: Gibson-Bold;
  src: url('./fonts/gibson-bold.ttf');
}

a {
  color: inherit;
  text-decoration: none;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #ba0000;
}

::-webkit-scrollbar-track {
  background-color: white;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: Gibson-Regular;
}

.separador {
  background-color: #cccccc;
  width: 3rem;
  height: 0.5rem;
  display: block;
  margin: 0.75rem auto 1.2rem auto;
  border-radius: 0.5rem;
}
.d-flex { 
  display: flex;
}

.d-flex1 { 
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
}

.d-flex1 input{
  width: 70%;
  min-width: 70%;
  padding-left: 0;
  padding-right: 0;
}

.d-flex1 p{
  width: 30%;
  text-align: start;
  padding: 0 10px;
}

.font-white {
  color: white;
  font-size: 1.5rem;
}

.swal2-styled.swal2-confirm{
  background-color: #ab1414c5;
}
.swal2-styled.swal2-cancel{
  color: #ab1414c5;
  background-color: rgb(210,210,210);
  
}

.title-info-table{
  text-align: center;
  font-size: 1.5rem;
  color: #ba0000;
  margin: 10px 0;
}

.search-table {
  width: 100%;
  background-color: white;
  margin-top: 1rem;
  border-spacing: 0px;
}

.table-pending{
  width: 95%;
  margin:auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.title-table {
  background-color: #ba0000;
  color: white;
  display: flex;
  min-width: 100%;
}

.grow1{
  display: flex;
  align-items: center;
  justify-content: center;
}

.grow2{
  display: flex;
  align-items: center;
  justify-content: center;

}

.grow3{
  display: flex;
  align-items: center;
  justify-content: center;

}

.search-table tr {
  padding: 5px;
  display: flex;
}

.search-table th{
  border-bottom: none!important;
}
.search-table th,
.search-table td {
  line-height: 1rem;
  font-size: 1rem;
  text-align: center;
  margin: 0px auto;
  padding: 0.5rem 0;
  border-bottom: 1px solid lightgray;
  min-width: 150px;
  white-space: nowrap;
}

.table-pending th,
.table-pending td {
min-width: auto!important;
}

.no-results {
  background-color: white;
  text-align: center;
  padding: 0.7rem 0;
  font-size: 1rem;
}
.search-table th {
  padding-top: 1.2rem;
}
td.td-opc {
  display: flex;
  justify-content: center;
}
.td-opc div {
  margin: 0px 5px;
  cursor: pointer;
}
.td-opc p {
  font-size: 0.7rem;
}

tbody tr:nth-child(2n + 1) {
  background-color: #e8edf1;
}

.btn-inicio {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 1rem 2rem;
  font-size: 1rem;
  background-color: #f39200;
  font-weight: bold;
  color: white;
  position: absolute;
  bottom: 2%;
}
.btn-inicio svg {
  margin-right: 0.2rem;
  height: 1rem;
  width: 1rem;
}

/* group controls */
.group-controls {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-view {
  padding: 2px 5px;
  color: #f39200;
  font-size: 8px;
}

.btn-view:hover {
  background-color: #f39200;
  padding: 2px 5px;
  color: white;
}

.btn-edit {
  padding: 2px 12px;
  color: #2288c6;
  font-size: 8px;
}

.btn-delete svg, .btn-edit svg, .btn-view svg {
  width: 0.9rem;
  height: 0.9rem;
}
.btn-edit:hover {
  background-color: #2288c6;
  padding: 2px 12px;
  color: white;
}

.btn-delete {
  padding: 2px 10px;
  color: #e94f1b;
  font-size: 8px;
}

.btn-delete:hover {
  background-color: #e94f1b;
  padding: 2px 10px;
  color: white;
}

.formselect-container{
  display: flex;
  flex-wrap: wrap;
}
.formselect-item{
  padding: 0.5rem;
  border-radius: 0.3rem;
  width: max-content;
  cursor: pointer;
  width: 33.3%;
}

.formselect-item:hover{
  background-color: #ba00006b;
}

.formselect-item svg{
  margin: 0.5rem auto;
  display: flex;
  width: 2rem;
  height: auto;
}

.formselect-item p{
  text-align: center;
}

.formselect-item button{
  cursor: pointer;
  display: block;
  border: none;
  padding: 0.5rem 0.3rem;
  border-radius: 5px;
  margin: 0.5rem auto;
}

.w-30{
  width: 30%!important;
}

@media print {
  body, html, div {
    background-color: white;
    width: 100%;
    margin: 0;
    height: auto;
  }

  .svg_auto {
    width: 100%;
    height: auto;     
    transform: scale(0.8);
  } 
}

@media print {
  .hide-on-print {
    display: none;
  }
}

@media print {
  .apexcharts-legend { 
    overflow: visible !important; 
    max-height: none !important; 
  }

  .campGraficos {
    height: auto !important;
  }

  .graficoMultiReport {
    height: auto !important;
  }

  body, html {
    overflow: hidden !important;
  }
}
