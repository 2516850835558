.containerCad {
  background-color: white;
  border-radius: 15px;
  width: 100%;
  margin: 0 auto;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  height: 90%;
}

h1.titleForm {
  font-size: 1.5rem;
  white-space: nowrap;
  color: #ba0000;
  border-bottom: solid 3px #ba00006b;
  width: min-content;
  padding-bottom: 5px;
  font-family: Gibson-Regular;
  margin-bottom: 15px;
}

.line {
  width: 100%;
  border-bottom: solid 2px #ccc;
  margin: 30px 0;
}

h1.titleForm strong {
  font-size: 1.5rem;
  white-space: nowrap;
  font-family: Gibson-Bold;
}

.input_w100 {
  width: 100%;
  margin-bottom: 10px;
}

.input_w70 {
  width: 70%;
  margin-bottom: 10px;
}

.input_w30 {
  width: 30%;
  margin-bottom: 10px;
}

.input_w50 {
  width: 47.5%;
  margin-bottom: 10px;
}

.input_w90 {
  width: 85%;
  margin-bottom: 10px;
}

.input_w10 {
  width: 11%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
}

.title_input {
  margin-left: 20px;
  font-size: 1rem;
}

.title_input strong {
  font-size: 0.8rem;
  white-space: nowrap;
  font-family: Gibson-Bold;
}

.campo_input {
  width: 100%;
  border: solid 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  margin: 5px 0 10px;
  -webkit-box-shadow: 3px 7px 5px -4px rgba(0, 0, 0, 0.18);
  -moz-box-shadow: 3px 7px 5px -4px rgba(0, 0, 0, 0.18);
  box-shadow: 3px 7px 5px -4px rgba(0, 0, 0, 0.18);
  font-size: 0.9rem;
  outline: 0;
}

.btn_cadastro {
  margin: 3% auto 0;
  width: 150px;
}

.btn_form {
  margin: 10% auto 0;
  width: 200px;
}

.btn {
  padding: 10px 15px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: white;
  background-color: #ba0000;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.select {
  background: white url('../assets/seta2.svg') 95.5% 50% no-repeat;
  padding: 0;
  z-index: 2;
  border-radius: 1rem;
  z-index: 2;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  margin: 0;
  background-color: rgba(255, 255, 255, 0);
}

.input_w10 a svg {
  color: #ba0000;
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.input_w10 span {
  color: rgba(255, 255, 255, 0);
}

.modal {
  position: absolute;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal .containerCadAuditoria {
  background-color: white;
  border-radius: 15px;
  width: 56%;
  max-width: 900px;
  margin: 15px auto;
  padding: 20px 3rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  overflow-y: auto;
}
.modal .containerCad {
  background-color: white;
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  margin: 15px auto;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
  overflow-y: auto;
}

.bg_modal {
  position: fixed;
  width: 100vw;
  min-height: 100vh;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.281);
  z-index: 1;
}
.btn_cadastro_view_audit {
  display: flex;
  justify-content: center;
}
.btn_cadastro_view_audit .btn {
  max-width: 30%;
  margin: 30px 5% 15px;
}

@media (max-width: 800px) {
  .modal .containerCad {
    background-color: white;
    border-radius: 15px;
    width: 90%;
    max-width: 800px;
    margin: 15px auto;
    padding: 20px 5%;
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: fixed;
    overflow-y: auto;
  }
  .modal .containerCadAuditoria {
    background-color: white;
    border-radius: 15px;
    width: 56%;
    max-width: 900px;
    margin: 15px auto;
    padding: 20px 3rem;
    display: flex;
    flex-direction: column;
    z-index: 3;
    position: fixed;
    overflow-y: auto;
  }
}
