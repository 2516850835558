.commentBlock {
  width: 100%;
  padding: 0.3rem;
  margin: 1rem 0;
  border-radius: 5px;
  /* background-color: #c733331c; */
  box-shadow: 1px 1px 4px rgb(0 0 0 / 50%);
}

.containerCommentStyle {
  position: fixed;
  z-index: 4;
  top: 3%;
  right: 3%;
}
.styleBlock {
  display: flex;
  align-items: center;
}

.textFieldStyle {
  width: 100%;
  border: 2px solid gray;
  border-radius: 5px;
  padding: 0.5rem;
}

.buttonStyle {
  width: max-content;
  height: max-content;
  margin: 0.1rem;
  border: none;
  padding: 0.25rem 0.25rem;
  border-radius: 1.5rem;
  cursor: pointer;
  background: white;
  display: flex;
}

.openAnswerButton {
  border: none;
  background-color: transparent;
  padding: 0;
  display: flex;
  cursor: pointer;
}
.commentOptions {
  width: 100;
  background: white;
  position: absolute;
  z-index: 4;
  padding: 0.3rem 0.5rem;
  border-radius: 0.3rem;
  right: -2px;
  border-radius: 5;
  font-size: 0.9rem;
  list-style: none;
}
.commentForm {
  position: absolute;
  right: 375px;
  width: 370px;
  padding: 0.5rem;
  background: white;
  border-radius: 8px;
  top: 10vh;
}
.commentPanel {
   width: 370px;
  position: absolute;
  height: max-content;
  padding: 1rem 0.5rem;
  border: 1px solid gray;
  right: -30px;
  min-height: 94vh;
  margin-right: 0;
  background: white;
}

@media (max-width: 550px) {
  .containerCommentStyle{
     top: 0;
  right: 0;

  margin: 0;
  }
.commentPanel{
    right: 0;
    width: 100vw;
    height: 100vh;
}
  
}