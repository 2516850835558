* {
  font-family: Gibson-Regular;
}
html,
body {
  background-color: #f3f3f3;
  width: 100vw;
  min-height: 100vh;
}

.container {
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #f3f3f3;
}
.containerRow {
  padding: 0;
  margin: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f3f3f3;
}

/* ---------------- Navbar ---------------- */
.navbar {
  width: 100%;
  padding: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  background-color: white;
  align-items: center;
  border-bottom: 2px solid #f3f3f3;
  height: 10vh;
}

.navbar_section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: min-content;
}

.section_item {
  margin: 0 20px;
  position: relative;
}


.icon_btn {
  border: none;
  background-color: white;
  color: #ba0000a1;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon_btn svg {
  margin: auto;
  width: 20px;
  height: 20px;
}

.icon_btn p {
  margin: 0 5px;
  color: black;
}


.area_user {
  width: 10vw;
  border-left: 2.5px solid #f3f3f3;
  padding: 0 10px;
}

.user {
  font-size: 0.8rem;
}

.btn_sair .icon_btn svg {
  transform: scaleX(-1);
}

.btn_sair .icon_btn p {
  font-size: 0.9rem;
}

.modal_container_1 {
  padding-top: 10px;
  position: absolute;
  right: -12%;
  top: 100%;
  min-width: 20vw;
}

.modal_container {
  padding-top: 10px;
  position: absolute;
  left: 15%;
  top: 100%;
  min-width: 12vw;
}

.modal_content {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.308);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.308);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.308);
  background-color: white;
  padding: 10px 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  width: 100%;
}

.modal_content a {
  border-bottom: solid 1.5px rgba(131, 130, 130, 0.192);
  width: 100%;
  padding-bottom: 10px;
  margin: 10px auto;
  cursor: pointer;
}

.modal_content a p {
  font-size: 0.7rem;
  text-align: center;
}

.modal_content a p strong {
  font-size: 0.7rem;
  font-family: Gibson-bold;
}

.color_red {
  color: #ba0000f8;
}

.notification_title {
  color: #ba0000f8;
  font-size: 0.9rem !important;
  border-bottom: solid 1.5px rgba(131, 130, 130, 0.192);
  width: 100%;
  padding-bottom: 10px;
  margin: 10px auto;
  text-align: start;
}

.img_audi {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(73, 72, 72);
}

.btn_voltar{
  position: absolute;
  right: 5%;
  top: 13vh;
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  color: #ba0000;
  cursor: pointer;
}

.btn_voltar span{
  margin-top: -25%;
  margin-left: 10%;
  font-size: 1rem;
}

.btn_voltar svg{
  color: #ba0000;
  width: max(3vw, 60px);
  height:max(3vw, 60px);
  padding: 0;
  margin: 0;
}

/*  ---------------- Main ---------------- */

.main {
  width: 82vw;
  margin-left: 18vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.sidebar {
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  min-width: 18vw;
  min-height: 100vh;
  background-color: white;
  padding: 4vh 0;
  align-self: stretch;
  position: fixed;
}

.content {
  display: flex;
  flex-grow: 1;
  padding: 2vw;
  flex-direction: column;

}

.svg_auto svg {
  width: auto;
  height: auto;
}

.containerGraficoMultiples {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  margin: 2rem 0;
  padding: 1rem 0 1rem; 
  width: 100%;
  margin: 1rem auto;
  position: relative;
  background-color: white;
}

.campGraficos{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.graficoMulti{
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.graficoMultiReport{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1rem 0;
}

.graficoMulti h2{
  margin: 1rem 0 2rem;
  font-size: 1.3rem;
  color: #ba0000;
}

.containerGraficoMultiples .graficoMulti .svg_auto {
  padding: 0.5rem;
  width: 100%;
  margin: 0px auto;
}

.graficoMultiReport h2{
  font-size: 1.2rem;
}

.graficoMultiReport svg g text tspan{
  font-size: 1rem;
}

.containerGraficoMultiplesReport h1{
  width: 100%;
  text-align: center;
  margin: 0.8rem auto;
  font-size: 1.5rem; 
  color: #ba0000;
  font-family: Gibson-Bold;
}

.containerGraficoMultiples h1,
.containerGraficoMain h1{
  width: 100%;
  text-align: left;
  margin: 1rem auto 2rem;
  font-size: 1.2rem; 
  color: #ba0000;
  font-family: Gibson-Regular;
}

tspan{
  font-size: 1vw;
}

/*  ---------------- Sidebar ---------------- */

.info_user {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 40px;
  border-bottom: 1px solid #ba0000b9;
}

.info_user img {
  width: 10vw;
  height: 6vw;
}

.img_user img {
  width: 5vw;
  height: 5vw;
  border-radius: 15px;
  background-color: white;
}

.menu_title {
  font-family: Gibson-Bold;
  color: #ba0000e8;
  text-align: center;
  margin-top: 8vh;
  letter-spacing: 0.2rem;
  font-size: 1rem;
}

.menu_list{
  margin: 3vh 0 8vh;
}


.menu_item {
  width: 100%;
  padding: 1vh  10px;
}

.menu_item:hover {
  width: 100%;
  padding: 1vh  10px;
  background-color: #f3f3f3;
}

.item_link {
  width: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.616);
}

.item_link svg {
  width: 20px;
  height: 20px;
  color: #ba00008f;
  margin-right: 15px;
}

.menu_item:hover .item_link {
  width: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
}

.menu_item:hover .item_link svg {
  width: 20px;
  height: 20px;
  color: #ba0000;
  margin-right: 15px;
}

.menu_item:active .item_link {
  width: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
}

.menu_item:active .item_link svg {
  width: 20px;
  height: 20px;
  color: #ba0000;
  margin-right: 15px;
}

.active {
  width: 100%;
  padding: 10px 10px;
  background-color: #f3f3f3;
}

.active .item_link {
  width: 100%;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
}

.active .item_link svg {
  width: 20px;
  height: 20px;
  color: #ba0000;
  margin-right: 15px;
}

.help {
  margin: 2vh 10px 0;
}

.help a {
  display: flex;
  justify-content: center;
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
  margin: 10px 0;
}

.help a svg {
  color: #ba0000;
}

.LogoRisc {
  width: 100%;
  display: flex;
  justify-content: center;
}

.LogoRisc img {
  width: 5.5vw;
  height: 5.5vw;
}

.menuMobile {
  display: flex;
}

.btn_drop {
  display: none;
}

.menu_close {
  display: none;
}

/*  ---------------- Content ---------------- */

.title {
  font-size: 1.8rem;
  color: #ba0000;
  font-family: Gibson-Bold;
  text-align: center;
  width: 100%;
  margin: 50px auto 10px;
}

.subtitle {
  font-size: 1.5rem;
  color: #ba0000;
  font-family: Gibson-Bold;
  text-align: center;
  width: 100%;
  margin: 10px auto 8vh;
}

.LogoCometa {
  width: 55%;
  margin: 5vh 0 10px 30%;
  margin-bottom: 10px;
}

.title_main {
  font-size: 1.8rem;
  font-family: Gibson-Bold;
  color: #ba0000;
  padding-bottom: 5px;
  border-bottom: solid 2px #ba000085;
  width: min-content;
  margin-bottom: 3vh;
}

.flex_row_bet {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex_row_center {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex_row_ev{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 3vmax;
}

.flex_row_around {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 1vw;
  padding: 5px;
  background-color: gainsboro;
}

.flex_row_around:hover {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 5px 1vw;
  padding: 5px;
  background-color: rgba(170, 170, 170, 0.116);
}

.flex_row_around p {
  font-size: 0.9rem;
}

.flex_row_around button {
  border: solid 1px #ba0000;
  background-color: white;
  font-size: 0.9rem;
  color: #ba0000;
  cursor: pointer;
  padding: 0px 5px;
}

.flex_column_bet {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.opc_cadastro {
  margin-top: 2vmax;
  width: 30%;
  min-width: 290px;
  height: max-content;
}

.item_cadastro {
  padding: 3vmax 30px 3vmax;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 70%;
}

.img_cadastro {
  width: 5vw;
  height: 5vw;
  border: solid 2px #ba0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img_cadastro svg {
  color: #ba0000;
  width: 3vw;
  height: 3vw;
}

.name_cadastro {
  margin: 2vmax 0;
}

.name_cadastro p {
  color: #ba0000;
  font-size: 1.2rem;
}

.name_cadastro .barra {
  font-family: Gibson-Bold;
  color: #ba0000;
  font-size: 1.6rem;

  margin-top: -20px;
  margin-bottom: 3vmax;
}

.resume_cadastro {
  font-size: 0.8rem;
}

.btn_cadastro {
  margin-top: 15%;
  width: 150px;
}

.btn_bunscar {
  margin: 20px auto;
  width: 120px;
}

.btn {
  padding: 8px 12px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
  background-color: #ba0000;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.btn svg {
  width: 20px;
  height: 20px;
}

.info_cadastro {
  margin: 0px 5px 20px;
  width: 18%;
  background-color: #ba0000a6;
  border-radius: 10px;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffff;
}

.info_title {
  font-size: 0.8em;
  text-align: center;
}

.info_value {
  font-size: 1.3rem;
  font-family: Gibson-Bold;
}

/* CSS para os Gráficos- Dashboard*/

.containerDash {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  height: max-content;
  }

  .containerTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    height: max-content;
    background-color: white;
    padding: 2vw;
    border-radius: 15px;
    }

.containerGrafico {
  width: 100%
}
.containerGraficoMain {
  width: 100%;
  min-width: 66vw;
  background-color: white;
  padding: 2vw;
  border-radius: 15px;
}
.containerGraficoMain canvas {
  width: 100%;
}
@media (max-width: 550px) {
  .section_item {
    margin: 0 5px;
    position: relative;
  }
  .title_page {
    color: #ba0000cb;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .area_user {
    display: none;
    width: 10vw;
    border-left: 2.5px solid #f3f3f3;
    padding: 0 10px;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-width: 50vw;
    background-color: white;
    padding: 5% 0 0;
    position: fixed;
    bottom: 0;
    height: 100vh;
  }

  .menuMobile {
    display: none;
  }

  .btn_drop {
    position: fixed;
    background-color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    display: block;
  }

  .btn_drop svg {
    color: #ba0000;
  }

  .menu_close {
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
  }

  .menu_close .btn_close {
    background-color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
  }

  .btn_close svg {
    color: #ba0000;
  }

  .opc_cadastro {
    width: 100%;
    min-width: none;
    height: max-content;
    margin: 20px 0;
  }
}

@media (min-width: 551px) and (max-width: 810px) {
  .section_item {
    margin: 0 5px;
    position: relative;
  }
  .title_page {
    color: #ba0000cb;
    font-size: 1.4rem;
    letter-spacing: 0.2rem;
  }
  .area_user {
    display: none;
    width: 10vw;
    border-left: 2.5px solid #f3f3f3;
    padding: 0 10px;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    min-width: 40vw;
    background-color: white;
    padding: 5% 0 0;
    position: fixed;
    bottom: 0;
    height: 100vh;
  }
  .menuMobile {
    display: none;
  }
  .btn_drop {
    position: fixed;
    background-color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
    display: block;
  }

  .btn_drop svg {
    color: #ba0000;
  }

  .menu_close {
    min-width: 100px;
    display: flex;
    justify-content: flex-end;
  }

  .menu_close .btn_close {
    background-color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 50%;
  }

  .btn_close svg {
    color: #ba0000;
  }
  .opc_cadastro {
    width: 48%;
    min-width: 100px;
    height: max-content;
    margin: 20px 0;
    max-width: none;
  }

  .img_user {
    width: 12vw;
    height: 12vw;
    background-color: #ba0000dc;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .img_user img {
    width: 11vw;
    height: 11vw;
    border-radius: 15px;
    background-color: white;
  }
}

.tabelaBrands{
  border-spacing: 0px;
}

.tabelaBrands td, .tabelaBrands th{
  padding: 10px;
  border: 1px solid gray;
  margin: 0;
}


.ContainerImprimir{
  background-color: white;
  border-radius: 15px;
  width: 90%;
  max-width: 800px;
  height: auto;
  margin: 15px auto;
  padding: 20px 5%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  position: relative;
}
.imprimir{
  width: 100vw;
  height: auto;
}
